import { ReactElement } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import {
  Box,
  Flex,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  Grid,
  GridItem,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';

import GeekLogo from 'components/atoms/images/geekLogo';
import HeaderLinkLabel from 'components/atoms/headerLinkLabel';
import HeaderLinkButton from 'components/atoms/headerLinkButton';
import { ROOT, DASHBOARD, LOGIN } from 'lib/constants/paths';
import { AUTH_TOKEN } from 'lib/constants/auth';
import { USER_TYPE } from 'lib/constants/cookies';

export type LandingPageHeaderLinks = Array<{
  isFeatured: boolean;
  label: string;
  path: string;
}>;

export interface LandingPageHeaderProps {
  headerLinks: LandingPageHeaderLinks;
  featuredLogin?: boolean;
}

const LandingPageHeader = ({
  headerLinks,
  featuredLogin = false,
}: LandingPageHeaderProps): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();
  const [cookies] = useCookies([AUTH_TOKEN, USER_TYPE]);
  const dashboardLink = DASHBOARD(cookies.user_type);

  return (
    <Box bg='white' position='fixed' zIndex={10} left={0} top={0} w='100%'>
      <Flex justify='space-between' alignItems='center' p='16px 32px'>
        <Link href={ROOT} cursor='pointer'>
          <GeekLogo />
        </Link>

        <Flex display={['none', 'none', 'flex', 'flex']}>
          {headerLinks.map((link) => (
            <NextLink
              passHref
              key={link.path}
              href={{
                pathname: link.path,
              }}
            >
              <Box>
                {link.isFeatured ? (
                  <HeaderLinkButton
                    isSelected={router.asPath.includes(link.path)}
                  >
                    {link.label}
                  </HeaderLinkButton>
                ) : (
                  <HeaderLinkLabel
                    isSelected={router.asPath.includes(link.path)}
                  >
                    {link.label}
                  </HeaderLinkLabel>
                )}
              </Box>
            </NextLink>
          ))}

          {cookies.auth_token ? (
            <NextLink passHref href={dashboardLink}>
              <Box>
                <HeaderLinkLabel
                  isSelected={router.asPath.includes(dashboardLink)}
                >
                  Entrar
                </HeaderLinkLabel>
              </Box>
            </NextLink>
          ) : null}

          {!cookies.auth_token && featuredLogin ? (
            <NextLink passHref href={LOGIN}>
              <Box>
                <HeaderLinkButton isSelected={router.asPath.includes(LOGIN)}>
                  Login
                </HeaderLinkButton>
              </Box>
            </NextLink>
          ) : null}

          {!cookies.auth_token && !featuredLogin ? (
            <NextLink passHref href={LOGIN}>
              <Box>
                <HeaderLinkLabel isSelected={router.asPath.includes(LOGIN)}>
                  Login
                </HeaderLinkLabel>
              </Box>
            </NextLink>
          ) : null}
        </Flex>

        <Icon
          color='black'
          as={FiMenu}
          display={['block', 'block', 'none', 'none']}
          w={6}
          h={6}
          onClick={onOpen}
        />

        <Drawer
          isFullHeight={false}
          placement='right'
          onClose={onClose}
          isOpen={isOpen}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton top={6} right={6} color='black' />
            <DrawerBody px={0} py={20} bgColor='white'>
              <Grid templateColumns='repeat(1, 1fr)' gap={6}>
                {headerLinks.map((link) => (
                  <GridItem key={link.path}>
                    <NextLink passHref href={link.path}>
                      <Box>
                        <HeaderLinkLabel
                          isSelected={router.asPath.includes(link.path)}
                        >
                          {link.label}
                        </HeaderLinkLabel>
                      </Box>
                    </NextLink>
                  </GridItem>
                ))}
                {cookies.auth_token ? (
                  <GridItem>
                    <NextLink passHref href={dashboardLink}>
                      <Box>
                        <HeaderLinkLabel
                          isSelected={router.asPath.includes(dashboardLink)}
                        >
                          Dashboard
                        </HeaderLinkLabel>
                      </Box>
                    </NextLink>
                  </GridItem>
                ) : (
                  <GridItem key={LOGIN}>
                    <NextLink passHref href={LOGIN}>
                      <Box>
                        <HeaderLinkLabel
                          isSelected={router.asPath.includes(LOGIN)}
                        >
                          Login
                        </HeaderLinkLabel>
                      </Box>
                    </NextLink>
                  </GridItem>
                )}
              </Grid>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};

export default LandingPageHeader;
