import { ReactElement } from 'react';
import { Box, Grid, GridItem, Text, Flex, VStack } from '@chakra-ui/react';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import useTranslation from 'next-translate/useTranslation';

import { FooterLinks } from 'components/molecules/footer/links';
import { FooterLinksIcons } from 'components/molecules/footer/linkIcons/index';
import * as paths from 'lib/constants/paths';
import GeekLogo from 'components/atoms/images/geekLogo';
import { TRANSLATE_NAMESPACES } from 'lib/constants/translateNamespaces';

const forDevsLinks = [
  {
    label: 'Blog de TI',
    path: paths.BLOG_TECH,
    external: true,
  },
  {
    label: 'Vagas de tecnologia',
    subPaths: [
      {
        label: 'Vagas javascript',
        path: paths.JOBS_JAVASCRIPT,
      },
      {
        label: 'Vagas java',
        path: paths.JOBS_JAVA,
      },
      {
        label: 'Vagas node.js',
        path: paths.JOBS_NODEJS,
      },
      {
        label: 'Vagas PHP',
        path: paths.JOBS_PHP,
      },
      {
        label: 'Vagas react',
        path: paths.JOBS_REACT,
      },
      {
        label: 'Vagas python',
        path: paths.JOBS_PYTHON,
      },
    ],
  },
];

const forCompaniesLinks = [
  {
    label: 'Blog de RH Tech',
    path: paths.BLOG_RHTECH,
    external: true,
  },
  {
    label: 'Tech Recruiter as a Service',
    path: paths.TRAAS,
    external: false,
  },
  {
    label: 'GeekAcademy',
    path: paths.GEEK_ACADEMY,
    external: true,
  },
  {
    label: 'Comece a contratar',
    path: paths.COMPANY_LP,
    external: false,
  },
];

const geekhunterLinks = [
  {
    label: 'Sobre a GeekHunter',
    path: paths.ABOUT,
  },
  {
    label: 'Suporte',
    path: paths.HELP,
  },
  {
    label: 'Política de privacidade',
    path: paths.POLITICS,
  },
  {
    label: 'Termos de uso',
    path: paths.TERMS,
  },
  {
    label: 'Portal LGPD',
    path: paths.DATA_PRIVACY,
  },
];

const footerIconLinks = [
  {
    icon: AiFillInstagram,
    path: paths.INSTAGRAM,
  },
  {
    icon: FaLinkedin,
    path: paths.LINKEDIN,
  },
  {
    icon: FaYoutube,
    path: paths.YOUTUBE,
  },
];

export const Footer = (): ReactElement => {
  const { t } = useTranslation(TRANSLATE_NAMESPACES.plataformFooter);

  return (
    <Box as='footer'>
      <Flex
        bgColor='black'
        flexDir={{ base: 'column', md: 'row' }}
        justify='space-evenly'
        p={16}
      >
        <VStack
          alignItems='flex-start'
          mb={{ base: 16, md: 0 }}
          spacing={4}
          w={40}
        >
          <GeekLogo type='horizontal' />

          <Text fontSize='xs' color='white'>
            Rod. José Carlos Daux - SC
            <br />
            401, 4120 - km 4, Bairro Saco
            <br />
            Grande - Florianópolis - SC
            <br />
            CEP 88032-005
          </Text>
        </VStack>

        <Grid
          gap={{ base: 16, md: 4 }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          w={{ base: '100%', md: '60%' }}
        >
          <FooterLinks title='Para Profissionais' footerLinks={forDevsLinks} />
          <FooterLinks title='Para empresas' footerLinks={forCompaniesLinks} />
          <FooterLinks title='A Geekhunter' footerLinks={geekhunterLinks} />
        </Grid>
      </Flex>

      <Grid
        borderTopWidth={1}
        borderTopStyle='solid'
        borderTopColor='black.600'
        bgColor='black'
        gap={8}
        px={16}
        py={4}
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
      >
        <FooterLinksIcons footerIconLinks={footerIconLinks} />

        <GridItem color='grey.300'>
          <Flex
            alignItems='flex-start'
            justify='flex-end'
            fontSize='xs'
            color='white'
          >
            <Text mr={{ base: 0, md: 1 }}>
              {`© 2015 - ${new Date().getFullYear()} `}
              {t('geekhunter')}
              {`. `}
            </Text>
            <Text>Todos os direitos reservados.</Text>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
