import { ReactElement } from 'react';
import { GridItem, Flex, Text, VStack } from '@chakra-ui/react';

import { Accordion } from 'components/atoms/accordion';
import { Link } from 'components/atoms/link';

export type FooterLinksType = Array<{
  label: string;
  path?: string;
  external?: boolean;
  subPaths?: Array<{
    label: string;
    path: string;
  }>;
}>;

export interface FooterLinksProps {
  title: string;
  footerLinks: FooterLinksType;
}

export const FooterLinks = ({
  title,
  footerLinks,
}: FooterLinksProps): ReactElement => (
  <GridItem>
    <VStack
      alignItems={{
        base: 'flex-start',
        md: 'center',
      }}
    >
      <Text
        color='white'
        fontSize='sm'
        fontWeight={700}
        textAlign='start'
        textTransform='uppercase'
        w={40}
        mb={4}
      >
        {title}
      </Text>

      <Flex flexDir='column'>
        {footerLinks.map((link) => {
          if (link.subPaths && link.subPaths.length > 0) {
            return (
              <Accordion key={link.label} label={link.label} w={40} mb={4}>
                {link.subPaths.map((subLink) => (
                  <Link
                    data-testid='footer-link'
                    href={subLink.path || ''}
                    key={subLink.label}
                    mt={4}
                    textAlign='start'
                    textDecoration='none'
                    w={40}
                    fontSize='xs'
                    _hover={{
                      color: 'purple.100',
                    }}
                  >
                    {subLink.label}
                  </Link>
                ))}
              </Accordion>
            );
          }

          return (
            <Link
              data-testid='footer-link'
              href={link.path || ''}
              key={link.label}
              mb={4}
              textAlign='start'
              textDecoration='none'
              fontSize='xs'
              _hover={{
                color: 'purple.100',
              }}
              external={link.external}
            >
              {link.label}
            </Link>
          );
        })}
      </Flex>
    </VStack>
  </GridItem>
);
