import { ReactElement } from 'react';
import { Flex, GridItem, Icon, Text } from '@chakra-ui/react';

import { IconType } from 'lib/@types';

export interface FooterLinksIconsProps {
  footerIconLinks: Array<{
    icon: IconType;
    path: string;
  }>;
}

export const FooterLinksIcons = ({
  footerIconLinks,
}: FooterLinksIconsProps): ReactElement => (
  <GridItem>
    <Flex
      w={{
        base: '100%',
        md: 'auto',
      }}
      justify={{
        base: 'flex-start',
        md: 'flex-start',
      }}
    >
      {footerIconLinks.map((link) => (
        <a
          target='_blank'
          key={link.path}
          href={link.path}
          data-testid='footer-icon-link'
          rel='noopener noreferrer'
        >
          <Text
            display='inline-block'
            fontSize='12px'
            color='white'
            textDecoration='underline'
            _hover={{
              color: 'purple.100',
            }}
          >
            <Icon
              as={link.icon}
              borderRadius='2px'
              h={4}
              mb={-2}
              mr={4}
              w={4}
            />
          </Text>
        </a>
      ))}
    </Flex>
  </GridItem>
);
