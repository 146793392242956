import { ReactElement } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

import { PropsWithChildren } from 'lib/@types';

export interface HeaderLinkButtonProps extends TextProps {
  isSelected?: boolean;
}

const HeaderLinkButton = ({
  isSelected = false,
  children,
  ...props
}: PropsWithChildren<HeaderLinkButtonProps>): ReactElement => {
  const selectedStyle = {
    bgColor: 'primary.300',
    borderColor: 'primary.300',
    color: 'primary.50',
  };

  return (
    <Text
      _hover={selectedStyle}
      bgColor='primary.500'
      borderWidth={2}
      borderColor='primary.500'
      borderRadius='base'
      color='white.100'
      cursor='pointer'
      fontFamily='body'
      fontSize={10}
      fontWeight='700'
      letterSpacing={1.2}
      ml={8}
      px={6}
      paddingTop={1.5}
      paddingBottom={1}
      sx={isSelected ? selectedStyle : {}}
      textTransform='uppercase'
      transition='0.2s'
      {...props}
    >
      {children}
    </Text>
  );
};

export default HeaderLinkButton;
