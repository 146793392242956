import * as paths from 'lib/constants/paths';

export const headerLinks = [
  {
    isFeatured: false,
    label: 'Vagas',
    path: paths.JOBS,
  },
  {
    isFeatured: false,
    label: 'Para Profissionais',
    path: paths.CANDIDATE_LP,
  },
  {
    isFeatured: true,
    label: 'sou empresa',
    path: paths.COMPANY_LP,
  },
];

// backward compatibility with old sign up flow pages
export const companiesLPHeaderLinks = [
  {
    isFeatured: false,
    label: 'Para empresas',
    path: paths.COMPANY_LP,
  },
  {
    isFeatured: false,
    label: 'Para Profissionais',
    path: paths.CANDIDATE_LP,
  },
];

export default headerLinks;
