import { ReactElement } from 'react';
import NextLink from 'next/link';
import { Text, TextProps, Icon as IconChakra, Flex } from '@chakra-ui/react';
import { HiOutlineExternalLink } from 'react-icons/hi';

import { PropsWithChildren } from 'lib/@types';

interface LinkProps extends TextProps {
  href: string;
  external?: boolean;
}

export const Link = ({
  href,
  external,
  children,
  ...props
}: PropsWithChildren<LinkProps>): ReactElement => (
  <Flex>
    {external === true ? (
      <a target='_blank' href={href} rel='noopener noreferrer'>
        <Flex alignItems='flex-start' cursor='pointer'>
          <Text
            display='inline-block'
            fontSize='12px'
            color='white'
            textDecoration='underline'
            _hover={{
              color: 'secondary.500',
            }}
            {...props}
          >
            {children}
            <IconChakra
              as={HiOutlineExternalLink}
              w={5}
              h={5}
              ml={[1, 1, 2, 2]}
            />
          </Text>
        </Flex>
      </a>
    ) : (
      <NextLink passHref href={href}>
        <Flex alignItems='flex-start' cursor='pointer'>
          <Text
            display='inline-block'
            fontSize='12px'
            color='white'
            textDecoration='underline'
            _hover={{
              color: 'secondary.500',
            }}
            {...props}
          >
            {children}
          </Text>
        </Flex>
      </NextLink>
    )}
  </Flex>
);
