import { ReactElement } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps as ChakraAccordionProps,
  Text,
} from '@chakra-ui/react';

export interface AccordionProps extends ChakraAccordionProps {
  label: string;
}

export const Accordion = ({
  label,
  children,
  ...props
}: AccordionProps): ReactElement => (
  <ChakraAccordion allowToggle {...props}>
    <AccordionItem border={0}>
      <AccordionButton
        _focus={{ boxShadow: '0 0' }}
        color='white'
        p={0}
        fontSize='xs'
        _hover={{
          color: 'purple.100',
        }}
      >
        <Text>{label}</Text>

        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel p={0}>{children}</AccordionPanel>
    </AccordionItem>
  </ChakraAccordion>
);
